import Vue from 'vue'
import App from './App.vue'


// 引入elementUi
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

//引入路由
import VueRouter from 'vue-router'
//引入路由器
import router from './router'
Vue.use(VueRouter)
import i18n from './lang/index'
import './assets/icon/icon.css'

Vue.use(ElementUI);

import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

import ImageIpreview from 'image-ipreview';
import 'image-ipreview/lib/image-ipreview.css';
Vue.use(ImageIpreview);

// 引入axios
import './utils/axios'

// 引入默认样式
import '../src/assets/css/reset.css'
// 引入字体
// import '../src/assets/css/font.css'


import tool from './utils/tool.js'
Vue.use(tool)

import moment from 'moment'

Vue.config.productionTip = false;
Vue.filter('dateFormat', function (value) {
  return moment(value).format('YYYY-MM-DD');
});


new Vue({
  i18n,
  render: h => h(App),
  router: router,
  beforeCreate() {
    Vue.prototype.$bus = this //挂载全局事件总线对象
  },
}).$mount('#app')
