<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
        let url = window.location.href;
    let params = url.split("?")[1];
    let paramsObj = {};
    if (params) {
      let paramsArr = params.split("&");
      for (let i = 0; i < paramsArr.length; i++) {
        let param = paramsArr[i].split("=");
        paramsObj[param[0]] = param[1];
      }
    }
    let lang = paramsObj.lang;
    if(lang == undefined){
      window.localStorage.setItem("lang", 'en');
    }else{
      window.localStorage.setItem("lang", lang);
    }
    
  },
};
</script>

<style>
/* *{
  font-family: miSans !important;
} */
body {
  overflow-x: hidden;
}
.container {
  width: 1200px;
  margin: 0 auto;
}
:root {
  --main-color: #1952a5;
}
</style>
