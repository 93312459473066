import VueRouter from 'vue-router'
import axios from 'axios'
var title = ''
const router = new VueRouter({
    linkExactActiveClass: 'current',
    routes:[
        {
            name:'index',
            path:'/index',
            meta:{
                title:'首页',
            },
            component:()=> import('@/pages/home'),
        },
        {
            name:'login',
            path:'/login',
            meta:{
                title:'登录'
            },
            component: () => import('@/pages/home/login.vue')
        },
        {
            name:'register',
            path:'/register',
            meta:{
                title:'注册'
            },
            component: () => import("@/pages/home/register")
        },
        {
            name:'school',
            path:'/school',
            meta:{
                title:'语言学校'
            },
            component:() => import('@/pages/school'),
        },
        {
            name:'schoolDetail',
            path:'/schoolDetail',
            meta:{
                title:'语言学校'
            },
            component: ()=> import("@/pages/school/detail.vue")
        },
        {
            name:'schoolComment',
            path:'/schoolComment',
            meta:{
                title:'学校评论'
            },
            component: () => import("@/pages/school/comment.vue")
        },
        {
            name:'SchoolReserve',
            path:'/schoolReserve',
                        meta:{
                title:'学校预订'
            },
            component: ()=>import("@/pages/school/reserve.vue")
        },
        {
            name:'schoolSuccess',
            path:'/schoolSuccess',
            meta:{
                title:'预订成功'
            },
            component: () => import("@/pages/school/reserveSuccess.vue")
        },
        {
            name:'priceList',
            path:'/pricelist',
            meta:{
                title:'价格列表'
            },
            component: ()=>import("@/pages/school/priceList.vue")
        },
        {
            name:'AcademicList',
            meta:{
                title:'学历教育'
            },
            path:'/AcademicList',
            component: () => import("@/pages/AcademicEducation")
        },
        {
            name:'AcademicDetail',
            path:'/AcademicDetail',
            meta:{
                title:'学校详情'
            },
            component: () => import("@/pages/AcademicEducation/detail.vue")
        },
        {
            name:'AcademicReserve',
            path:'/AcademicReserve',
            meta:{
                title:'学校预订'
            },
            component: () => import("@/pages/AcademicEducation/reserve.vue")
        },
        {
            name:'AcademicPriceList',
            path:'/AcademicPriceList',
            meta:{
                title:'价格列表'
            },
            component: () => import("@/pages/AcademicEducation/priceList.vue")
        },
        {
            name:'AcademicSuccess',
            path:'/AcademicSuccess',
            meta:{
                title:'预订成功'
            },
            component: () => import("@/pages/AcademicEducation/success.vue")
        },
        {
            name:'AcademicOrdinaryReserve',
            path:'/AcademicOrdinaryReserve',
            meta:{
                title:'学校预订'
            },
            component: () => import("@/pages/AcademicEducation/ordinaryReserve.vue")
        },
        {
            name:'AcademicCustomReserve',
            path:'/AcademicCustomReserve',
            meta:{
                title:'学校预订'
            },
            component: () => import("@/pages/AcademicEducation/customReserve.vue")
        },
        {
            name:'VocationalSchool',
            path:'/VocationalSchool',
            meta:{
                title:'职业学校'
            },
            component: () => import("@/pages/VocationalSchool")
        },
        {
            name:'VocationalSchoolDetail',
            path:'/VocationalSchoolDetail',
            meta:{
                title:'学校详情'
            },
            component: () => import("@/pages/VocationalSchool/detail.vue")
        },
        {
            name:'VocationalSchoolPriceList',
            path:'/VocationalSchoolPriceList',
            meta:{
                title:'价格列表'
            },
            component: () => import("@/pages/VocationalSchool/priceList.vue")
        },
        {
            name:'VocationalSchoolReserve',
            path:'/VocationalSchoolReserve',
            meta:{
                title:'学校预订'
            },
            component: () => import("@/pages/VocationalSchool/reserve.vue")
        },
        {
            name:'VocationalSuccess',
            path:'/VocationalSuccess',
            meta:{
                title:'预订成功'
            },
            component: () => import("@/pages/VocationalSchool/success.vue")
        },
        {
            name:'medium',
            path:'/medium',
            meta:{
                title:'中介'
            },
            component:() => import('@/pages/medium')
        },
        {
            name:'mediumDetail',
            path:'/mediumDetail',
            meta:{
                title:'中介详情'
            },
            component: () => import('@/pages/medium/mediumDetail.vue')
        },
        {
            name:'mediumComment',
            path:'/mediumComment',
            meta:{
                title:'中介评论'
            },
            component: () => import('@/pages/medium/comment.vue')
        },
        {
            name:'mediumPriceList',
            path:'/mediumPriceList',
            meta:{
                title:'价格列表'
            },
            component: () => import('@/pages/medium/priceList.vue')
        },
        {
            name:'mediumPaySuccess',
            path:'/mediumPaySuccess',
            meta:{
                title:'支付成功'
            },
            component: () => import('@/pages/medium/paySuccess.vue')
        },
        {
            name:'mediumReserve',
            path:'/mediumReserve',
            meta:{
                title:'中介预订'
            },
            component: () => import('@/pages/medium/mediumReserve.vue')
        },
        {
            name:'group',
            path:'/group',
            meta:{
                title:'校友群'
            },
            component:() => import('@/pages/group')
        },
        {
            name:'groupInfo',
            path:'/groupInfo',
            meta:{
                title:'校友群'
            },
            component: () => import('@/pages/group/info.vue')
        },
        {
            name:'corperate',
            path:'/corperate',
            meta:{
                title:'合作洽谈'
            },
            component:() => import('@/pages/corperate'),
            redirect:"/corperate/schoolOperate",
            children:[
                {
                    name:'schoolOperate',
                    path:'schoolOperate',
                    meta:{
                        title:'学校合作'
                    },
                    component:() => import("@/pages/corperate/schoolCorperate.vue")
                },
                {
                    name:"mediumOperate",
                    path:'mediumOperate',
                    meta:{
                        title:'中介合作'
                    },
                    component: () => import("@/pages/corperate/mediumCorperate.vue")
                },
                {
                    name:"bussinessCor",
                    path:'bussinessCor',
                    meta:{
                        title:'商务洽谈'
                    },
                    component: () => import("@/pages/corperate/bussinessCor.vue")
                },
                {
                    name:"mediumSchCor",
                    path:'mediumSchCor',
                    meta:{
                        title:'中介合作'
                    },
                    component: () => import("@/pages/corperate/medium_school.vue")
                }
            ]
        },
        {
            name:'about',
            path:'/about',
            meta:{
                title:'关于我们'
            },
            component:() => import('@/pages/about')
        },
        {
            name:'accord',
            path:'/accord',
            meta:{
                title:'用户注册协议'
            },
            component:() => import('@/pages/about/accord.vue')
        },
        {
            name:'conceal',
            path:'/conceal',
            meta:{
                title:'隐私政策'
            },
            component:() => import('@/pages/about/conceal.vue')
        },
        {
            name:'retrieve',
            path:'/retrieve',
            meta:{
                title:'隐私政策'
            },
            component:() => import('@/pages/about/retrieve.vue')
        },
        {
            name:'contact',
            path:'/contact',
            meta:{
                title:'联系我们'
            },
            component:() => import('@/pages/about/contact.vue')
        },
        {
            name:'message',
            path:'/message',
            meta:{
                title:'信息'
            },
            component:() => import('@/pages/message'),
            children:[
                {
                    name:'Friend',
                    path:'friend',
                    meta:{
                        title:'朋友'
                    },
                    component: () => import('@/pages/message/friend.vue')
                },
                {
                    name:'Chat',
                    path:'chat',
                    meta:{
                        title:'聊天'
                    },
                    component: () => import('@/pages/message/chat.vue')
                },
                {
                    name:'Notice',
                    path:'notice',
                    meta:{
                        title:'同志'
                    },
                    component: () => import('@/pages/message/notice.vue')
                },
                {
                    name:'Contact',
                    path:'contact',
                    meta:{
                        title:'联系'
                    },
                    component: () => import('@/pages/message/contact.vue')
                },
                {
                    path:'/message',
                    meta:{
                        title:'信息'
                    },
                    redirect:'/message/friend'
                }
            ]
        },
        {
            name:'memberCenter',
            path:'/memberCenter',
            meta:{
                title:'个人中心'
            },
            component:()=>import("@/pages/memberCenter")
        },
        {
            name:'Erweicode',
            path:'/centerCode',
            meta:{
                title:'二维码'
            },
            component: () => import("@/pages/memberCenter/Erweicode.vue")
        },
        {
            name:'langOrderList',
            path:'/langSchollOrderList',
            meta:{
                title:'语言学校订单'
            },
            component: () => import("@/pages/order/langList.vue")
        },
        {
            name:'academicOrderList',
            path:'/academicOrderList',
            meta:{
                title:'学历教育订单'
            },
            component: () => import("@/pages/order/academicList.vue")
        },
        {
            name:'jobOrderList',
            path:'/jobOrderList',
            meta:{
                title:'职业教育订单'
            },
            component: () => import("@/pages/order/jobList.vue")
        },
        {
            name:'personOrderList',
            path:'/personOrderList',
            meta:{
                title:'私人订制订单'
            },
            component: () => import("@/pages/order/personList")
        },

        {
            name:'langOrderDetail',
            path:'/langOrderDetail',
            meta:{
                title:'语言学校订单'
            },
            component: () => import("@/pages/order/langDetail.vue")
        },
        {
            name:'academicOrderDetail',
            path:'/academicOrderDetail',
            meta:{
                title:'学历教育订单'
            },
            component: () => import("@/pages/order/academicDetail.vue")
        },
        {
            name:'jobOrderDetail',
            path:'/jobOrderDetail',
            meta:{
                title:'职业教育订单'
            },
            component: () => import("@/pages/order/jobDetail.vue")
        },
        {
            name:'personOrderDetail',
            path:'/personOrderDetail',
            meta:{
                title:'私人订制订单'
            },
            component: () => import("@/pages/order/personDetail.vue")
        },



        {
            name:'centerFriend',
            path:'/centerFriend',
            meta:{
                title:'我的朋友'
            },
            component: () => import("@/pages/memberCenter/memberFriend.vue")
        },
        {
            name:'centerOrder',
            path:'/centerOrder',
            meta:{
                title:'我的佣金'
            },
            component: () => import("@/pages/memberCenter/centerOrder.vue")
        },
        {
            name:'EnrollList',
            path:'/EnrollList',
            meta:{
                title:'我的订单'
            },
            component: () => import("@/pages/memberCenter/EnrollList.vue")
        },
        {
            name:'EnrollUnpay',
            path:'/EnrollUnpay',
            meta:{
                title:'订单详情'
            },
            component: () => import("@/pages/memberCenter/EnrollUnpay.vue")
        },
        {
            name:'enrollCommrize',
            path:'/enrollCommrize',
            meta:{
                title:'订单详情'
            },
            component: () => import("@/pages/memberCenter/enrollCommrize.vue")
        },
        {
            name:'centerSetting',
            path:'/centerSetting',
            meta:{
                title:'设置'
            },
            component: () => import("@/pages/memberCenter/setting.vue"),
            redirect:'/centerSetting/centerAccount',
            children:[
                {
                    name:'centerAccount',
                    path:'centerAccount',
                    meta:{
                        title:'设置'
                    },
                    component: () => import('@/pages/memberCenter/Account.vue')
                },
                {
                    name:'centerMessage',
                    path:'centerMessage',
                    meta:{
                        title:'设置'
                    },
                    component: () => import('@/pages/memberCenter/messageManage.vue')
                },
                {
                    name:'centerPrivacy',
                    path:'centerPrivacy',
                    meta:{
                        title:'设置'
                    },
                    component: () => import("@/pages/memberCenter/Privacy.vue")
                },
                {
                    name:'centerLogout',
                    path:'centerLogout',
                    meta:{
                        title:'设置'
                    },
                    component: () => import("@/pages/memberCenter/logout.vue")
                }
            ]
        },
        {
            name:'centerCooperation',
            path:'/centerCooperation',
            meta:{
                title:'我的合作洽谈'
            },
            component: () => import("@/pages/memberCenter/Cooperate.vue")
        },

        {
            name:'centermediumDetail',
            path:'/centermediumDetail',
            meta:{
                title:'中介洽谈'
            },
            component: () => import("@/pages/memberCenter/mediumDetail.vue")
        },
        {
            name:'centermediumSchoolDetail',
            path:'/centermediumSchoolDetail',
            meta:{
                title:'学校中介洽谈'
            },
            component: () => import("@/pages/memberCenter/mediumSchoolDetail.vue")
        },
        {
            name:'centerschoolDetail',
            path:'/centerschoolDetail',
            meta:{
                title:'学校洽谈'
            },
            component: () => import("@/pages/memberCenter/schoolDetail.vue")
        },
        {
            name:'centerbussinessDetail',
            path:'/centerbussinessDetail',
            meta:{
                title:'商务洽谈'
            },
            component: () => import("@/pages/memberCenter/bussinessDetail.vue")
        },

        {
            name:'centerQuestion',
            path:'/centerQuestion',
            meta:{
                title:'我的问题'
            },
            component: () => import("@/pages/memberCenter/CenterQuestion.vue")
        },
        {
            name:'centerCare',
            path:'/centerCare',
            meta:{
                title:'我的关注'
            },
            component: () => import('@/pages/memberCenter/centerCare.vue')
        },
        { path: '*', redirect: {name:'index'} }
    ]
})
router.beforeEach((to, from, next) => {
    
    window.scroll(0, 0); 
    axios.get('https://studyapi.academyshow.net/app/home/getInfo').then((res)=>{
        title = res.data.data.title;
        document.querySelector('meta[name="description"]').setAttribute('content',res.data.data.description)
        document.querySelector('meta[name="keywords"]').setAttribute('content',res.data.data.keyword)
    }).catch((err)=>{
    })
    next()
})
//全局后置-----------路由守卫
router.afterEach((to, from) => {
	if (to.meta.title) {
		document.title = title + '-' +to.meta.title
	}
})
export default router