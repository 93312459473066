export default {
    schoolInfo:{
        agentJie:'Introduction to Intermediary',
        agentAddress:'Intermediary Address',
        wechat:'Wechat',
        visa:'VisaType'
    },
    common: {
        selectProvince: "After selecting a country, you can filter provinces",
        selectRegion: "After selecting a province, you can filter at the city level",
        yes: 'yes',
        no: 'No',
        complaints: "Video Complaints",
        complaintsReason: "Reason for complaint",
        complaintsContent: "Complaint content",
        complaintsTips: "Please enter the complaint content",
        complaintsSuccess: "Successfully submitted, thank you for your feedback",
        submit: 'Submit',

        connectTitle: "Online Communication",
        connect: "Connect",
        onLine: 'OnLine',
        notOnLine: "Not onLine",
        date: 'Date',
        searchMedium: "Please enter the intermediary name for search",
        PlatformAgent: "Platform Agent",
        profile: 'Profile',
        enroll: "Enroll",
        tomore:'View more'
    },
    header: {
        nameIndex: 'Home',

        nameSchool: "FindSchool",
        nameLangSchool: "Language School",
        nameAcademicSchool: "Academic Education",
        nameJobSchool: "Vocational Education",

        nameMedium: "Intermediary",

        nameGroup: "AlumniGroup",

        nameCopoerate: "Cooperation",
        nameCopoerateSchool: "School",
        nameCopoerateMedium: 'Intermediary',
        nameCopoerateBussiness: "Business",

        nameAbout: "About",

        nameCompany: "Company profile",
        nameContact: "Contact platform",

        nameMessage: "Message",

        nameLanguage: "Language",
        nameChinese: "Chinese",
        nameEnglist: "English",

        nameLogin: "Login",

        nameProfile: "Profile",
        nameQRcode: "QR code",
        nameFollow: "Follow",
        nameEntroll: "Entroll",
        nameCooperation: "Cooperation",
        nameDoubt: 'Doubt',
        nameSetting: 'Setting',
        nameLogout: "Logout",
    },
    footer: {
        AppDown: "APP Store download",
        androidDown: "Android download",
        agreement: "Registration Agreement",
        policy: "Privacy Policy",
        recovery: "Account recovery",
        contact: "Contact us",
        message: "Online rumor exposure platform | Online harmful information reporting | Illegal and bad information reporting:400-140-2108 | Guarding hotline for minors:400-9922-556 | Report Mailbox:jubao@youxue.com"
    },
    login: {
        passwordLogin: "Password Login",
        codeLogin: "Code Login",
        plase: 'Please select',
        code: "Please input a code",
        requireCode: "Require code",
        againCode: "Retrieve code",
        phone: "Please enter your phone number",
        password: "Please input a password",
        forget: "Fotget Password",
        register: "No account/register now",
        nameLogin: "Login",
        agreement: "User Agreement",
        privacy: "Privacy Policy",
        and: "And",
        agree: "Check to agree by default",
        down: "Download Learning Tour APP",
    },
    register: {
        title: "Register",
        phone: "Please enter your mobile number",
        code: "Please enter the verification code",
        requireCode: "Require code",
        againCode: "Retrieve code",
        password: "Please input a password",
        againPassword: "Please confirm the password again",
        hasLogin: "Already have an account? Go to login",
        message: "Register and improve information",
        agreement: "User Agreement",
        privacy: "Privacy Policy",
        and: "And",
        agree: "Check to agree by default",
        down: "Download Learning Tour APP",
        inviteCode:'Please enter the invitation code'
    },
    infomation: {
        title: "Improve information",

        engTitle: 'English name',
        engInput: "Your English name",

        familyName: "Surname",
        familyInput: "Your Surname",

        sex: "Sex",
        sexMan: "Man",
        sexWoMan: "Woman",

        name: "Name",
        nameInput: "Your Name",

        phone: 'Phone',
        phoneInput: "Please enter the contact number",

        birthDate: "Birth Date",
        birthInput: "Please select",

        email: "Email",
        emailInput: "Please enter the mailbox number",

        passport: "Passport",
        passportInput: "Please enter your passport number",

        nation: 'Nationality',
        nationInput: "Please select",

        city: "City",
        cityInput: "Please enter your city",

        school: "School",
        schoolInput: "Please enter your school",
        invititaionCode: "InviteCode",
        invititaionInput: "Please enter the invitation code",

        perfect: "Improve later",
        save: "Preserve"
    },

    forget: {
        title: "Reset password",

        phonetitle: "Mobile verification",
        newPassoword: "New password",
        resetSuccess: "Success",

        phoneInput: "Please enter your mobile number",
        codeInput: "Please enter the code",
        requireCode: "Obtain code",
        againCode: "Retrieve code",
        next: "Next step",

        setnewpassowrd: "Please set a new password",
        againPassword: "Please enter the new password again",

        last: "Previous step",
        verify: "Mobile verification",

        sure: "Define"
    },
    agreement: {
        title: "User Agreement",
        content: "This agreement is a contract entered into between you and the administrator of this app, and has the legal effect of a contract. Please read it carefully<br/>I. The content, effectiveness, and changes of this agreement include the text of the agreement and all rules that have been published or may be published in the future by this APP. All rules are an integral part of this agreement and have the same legal effect as the text of the agreement. If you have any questions about the agreement, you should consult this APP. You cannot become an official user of this website until you agree to all the terms of the agreement and complete the registration process. After you click the I hereby read and agree to this APP user agreement and legal agreement button, this agreement will become effective and binding on both parties.. As long as you use this APP platform service, this agreement will be binding on you. At that time, you should not claim that this agreement is invalid or request to cancel this agreement for reasons such as not reading the contents of this agreement or not obtaining answers to your inquiries from this APP. You confirm that the terms of this agreement are a contract that deals with the rights and obligations of both parties, and are always valid. If there are otherwise mandatory provisions in law or special agreements between both parties, their provisions shall prevail<br/>You undertake to accept and abide by the provisions of this agreement. If you disagree with the agreement, you should immediately stop the registration process or stop using the APP platform services. The APP has the right to formulate and modify this agreement and various rules from time to time as needed, and publicize them on the APP platform without further notice to users. The changed agreement and rules will take effect as soon as they are published on the website. If you do not agree to the relevant changes, you should immediately stop <br/>"
    },
    privacy: {
        title: "Privacy Policy",
        content: "Privacy policy generally refers to the policies formulated by websites, applications, etc. based on privacy policies for the processing of user information<br/>For example, how to collect, use, save, share, and transfer this information when using products and/or services, and how to provide users with access, update, delete, and protect this information. Generally, users will carefully<br/>read and confirm that they have fully understood the content stated in this privacy policy before starting to use the product and/or service before using the product/service. Privacy policy generally refers to the policies formulated by websites, applications, etc. based on privacy policies for the processing of user information<br/>For example, how to collect, use, save, share, and transfer this information when using products and/or services, and how to provide users with access, update, delete, and protect this information. Generally, users will carefully read and confirm that they have fully understood the content stated in this privacy policy and agree to use the product/service before starting to use the product and/or service. Privacy policy generally refers to the policies formulated by websites, applications, etc. based on privacy policies for the processing of user information. For example, how to collect, use, save, share, and transfer this information when using products and/or services, and how to provide users with access, update, delete, and protect this information. Generally, users will carefully<br/>"
    },
    Profile: {
        attr: "Avatar",
        changeattr: "Renewal",

        enName: "English name",
        enNameInput: "Please fill in your English name",

        familyName: "Surname",
        familyNameInput: "Please fill in your surname",

        name: "Name",
        nameInput: "Please fill in your name",

        birthDate: "Birth date",
        birthDateInput: 'Please select a date of birth',

        sex: "Sex",
        sexMan: "Man",
        sexWoman: 'Woman',

        city: 'City',
        cityInput: "Please fill in the city",

        phone: "Phone",
        phoneInput: "Please fill in the contact number",

        school: "School",
        schoolInput: "University one is graduated from",

        email: "Email",
        emailInput: "Please fill in the email address",

        nation: "Nationality",
        nationInput: "Please select",

        passport: "Passport",
        passportInput: 'Please fill in your passport number',

        hide: 'Hide Display',

        change: "Modify data",

        save: "Preserve"
    },

    ErweiCode: {
        invite: "Sharing invitation",
        share: "Share QR code",
        myShare: "My exclusive invitation code",
        shareContent: "Successfully invited a friend to register for Golden Lotus and enter your invitation code. After the friend makes a purchase, you can receive a 5% commission bonus. Invite your friends to open a new world~",
        shareBtn: "Share",

        friend: "My Friend",
        order: "Subcontracting order",
    },
    centerFriend: {
        invite: "Sharing invitation",
        friend: "My Friend",
        order: "Subcontracting order",
        user: 'User',
        phone: 'Contact',
        creatTime: "CreatTime"
    },
    centerOrder: {
        invite: "Sharing invitation",
        friend: "My Friend",
        order: "Subcontracting order",
        orderNumber: "Order Number",
        money: 'Commission',
        time: "Transaction time",
        friend: "Friend"
    },
    centerCoroperate: {
        navSchool: "School settlement",
        navMedium: "Medium settlement",
        navBusiness: 'Business negotiation',
        tableType: "Type of settlement",
        name: "Name",
        contact: "Contact"
    },

    centerAccount: {
        changePassword: "Change Password",
        changePhone: "Modify phone number",

        phone: 'Phone',
        phonePlace: "Please enter your mobile number",

        code: "Code",
        codeInput: "Please enter the verification code",
        requireCode: "Obtain verification code",
        againCode: "Retrieve verification code",

        password: "Password",
        surePassword: "Confirm Password",
        setnewpassowrd: "Please set a new password",
        againPassword: "Please enter the new password again",

        sureChange: "Confirm modification",

        email: "Email",
        emailCode: "Email",
        emailInput: "Please enter the email code"
    },
    setting: {
        account: "Security",
        message: "Message",
        privacy: "Privacy",
        logout: "Logout",
        name: "Philippine Learning Tour Network"
    },
    school: {
        searchInput: "Search School",
        search: "Search",
        reset: "Reset",

        Comprehensive: "Comprehensive",
        Price: "Price",
        teachers: "Teachers",
        food: "Catering",
        environment: "Environmental",
        scale: "Scale",
        country: "Country",
        province: "Province",
        region: "Region",
        schoolType: "SchoolType",
        ScoringPrice: "Scoring Price",
        SalesRanking: "Sales Ranking"
    },
    agent: {
        searchInput: "Search Agent",
        search: "Search",
        reset: "Reset",
        Comprehensive: "Comprehensive",
        Price: "Price",
        sales: "Sales",
        country: "Country",
        region: "Region",
    },
    linksBar: {
        index: "Home",
        back: "Return to previous page",

        Findschool: 'Find School',

        langSchool: "Language School",
        langSchoolDetail: "Detail",
        langSchoolReserve: "Sign up now",
        langSchoolPriceList: "PriceList",
        langSchoolComment: "All evaluations",

        academicSchool: "Academic Education",
        academicSchoolDetail: "Academic Education Detail",

        jobSchool: "Vocational Education",
        jobSchoolDetail: "Vocational Education Detail",


        group: "Alumni group",
        joinGroup: "Join Alumni Group",
        about: 'About us',
        accord: "Accord",
        conceal: "Conceal",
        retrieve: "Retrieve",
        contact: "Contact",

        Cooperation: "Cooperation negotiation",
        schoolesettlement: "School settlement",
        myDoubt: "My Questions",

        medium: "Intermediary",
        mediumDetail: "Detail",
        mediumReserve: "Sign up now",
        mediumPriceList: "PriceList",
        mediumComment: "All evaluations",

        priceType: "Price Type",
        priceMoney: "Price",
        remark: "Remark",
        findAgent: "Find an intermediary",
        reserve: "Reserve",
        means: "Means",
        code: "Code",
        friend: "Friend",
        SubcontractingOrder: "Subcontracting order",
        cares: "Cares",
        entroll: "Entroll",
        coroperation: "Coroperation",
        doubt: "Doubt",
        setting: "Setting"
    },

    languageSchool: {
        morePic: "More pictures",
        contentlList: "Content List",
        complain: "Complain",
        connect: "Connect",
        attention: "Care",
        cancel: "Cancel Care",
        schoolBrief: "School Brief",
        schoolAddress: "School Address",
        enroll: "Enroll",
        notice: "Notice",
        dormitoryTable: "Dormitory Table",
        quotedPrice: "Quoted Price",
        basicRate: "Basic Rate",
        evaluation: "Evaluation",
        Intermediary: "Intermediary",
        apply: "Apply",


        nav1: "Latest Notifications/Events",
        nav2: "Dormitory Information Table",
        nav3: "Course automatic quotation",
        nav3Notice: "(This quotation does not include local fees. For details, please consult an intermediary or view the image.)",
        nav4: "Basic expenses",
        intime: "Admission",
        isPlane: "Pick up or not",
        planeType: "Pick Type",
        register: "Registration fee",
        other: "Other expenses",
        nav5: "Refund Notice",
        nav5Notice: "Three weeks in advance, a full free refund, two weeks in advance, 15% deduction, and one week in advance, 20% deduction; Deduct 50% if the student does not arrive at school within one week",
        getPrice: "Get a quote (discount)",
        nav6: "School evaluation",
        allComment: "View All",
        score: "Evaluation score",
        reply: "Reply",
        nav7: "Cooperative Intermediary",

        totalPrice: "Total expense",
        course: "Course",
        coursePeriod: "CoursePeriod",
        stayType: "StayType",
        stayTime: "StayTime",
        coustNotice: "Please consult the remaining situation of the intermediary dormitory before selecting. If multiple dormitory types are required, the accommodation time cannot exceed the learning cycle",
        addClass: "Add a course",
        deleteClass: "Delete",
        nav8: "Benefits available",
        unusable: "Not available",
        gradeuate: 'Graduation Date',
    },
    langReserve: {
        personMsg: "Basic personal information",
        nation: 'Nationality',
        familyName: "Surname",
        name: "Name",
        sex: "Sex",
        man: "Man",
        woman: "Woman",
        passport: "Passport",
        passportName: "PortName",
        birthDate: "Birth Date",
        old: "Old",
        phone: "Phone",
        email: "Email",
        address: "Address",

        demandMsg: "Filling in demand information",
        intime: "Admission",
        planeType: "Pick Type",
        isPlane: "IsPlane",
        course: "Course",
        coursePeriod: "Peroid",
        dormitoryWay: "Dormitory",
        dormitoryTime: "Long time",
        deleteClass: "Delete",
        classNotice: "Please consult the remaining situation of the intermediary dormitory before selecting. If multiple dormitory types are required, the accommodation time cannot exceed the learning cycle",
        register: "Registration fee",
        other: "Other expenses",
        addClass: "Add a course",

        emergencyTitle: "Add emergency contact",
        contactName: 'Contact Name',
        relation: "Relation",
        contact: 'Phone',
        sureContact: "Confirm Phone",

        discountTitle: "Registration discount",
        discountShare: "Benefits available",
        usable: 'Not available',

        gradeuate: "Gradeuate",
        year: "Year",
        month: 'Month',
        day: "Day",

        getPrice: "Get a quote",
        againPrice: "Get Quote Again",

        priceType: "Collection category",
        pricePeriod: "Period",
        priceMoney: "Amount Collected",
        remark: "Remarks",
        actualPrice: "Actual paid expenses",
        pay: "Sign up and pay",
        reuqitNotice: "Refund instructions: Three weeks in advance, full refund free of charge; Deduct 15% for two weeks in advance; Deduct 20% for one week in advance; Deduct 50% if you do not arrive at school within one week;",

        please:"Please select",
        inputFirName:"Please enter your last name",
        inputName:'Please enter your first name',
        inputpassportName:'Please enter your passport name',
        inputpassport:'Please enter your passport number',
        inputTime:'Select Date',
        inputPhone:'Please enter your contact phone number',
        inputEmail:"Please enter your email address",
        inputAddress:"Please enter your home address",
        yes:"Yes",
        no:'No',
        oneweek:'A week',
        twoweek:'Two weeks',
        threeweek:'Three weeks',
        forthweek:'four weeks',
        fiveweek:'Five weeks',
        sixweeK:"Six weeks",
        sevenweek:"Seven weeks",
        eightweek:"Eight weeks",
        nineweek:'Nine weeks',
        tenweek:'Ten weeks',
        emergencyName:"Please enter your name",
        emercontactType:"Please enter contact information",
        sureContactType:"Please confirm contact information",
        inputPlaneName:"Please enter the airline name",
        inputPlaneNum:"Please enter the flight number",
    },
    academicSchool: {
        morePic: "More Pictures",
        contentlList: "Content List",
        complain: "Complain",
        connect: "Connect",
        attention: "Care",
        cancel: "Cancel Care",
        schoolBrief: "School Brief",
        schoolAddress: "School Address",
        enroll: "Enroll",
        notice: "Notice",
        dormitoryTable: "Dormitory Table",
        quotedPrice: "Quoted Price",
        basicRate: "Basic Rate",
        evaluation: "Evaluation",
        Intermediary: "Intermediary",
        apply: "Apply",


        nav1: "Latest Notifications/Events",
        nav2: "Dormitory Information Table",
        nav3: "Course automatic quotation",
        nav3Notice: "(This quotation does not include local fees. For details, please consult an intermediary or view the image.)",
        nav4: "Basic expenses",
        intime: "Admission",
        isPlane: "Pick up or not",
        planeType: "Pick Type",
        register: "Registration fee",
        other: "Other expenses",
        nav5: "Refund Notice",
        nav5Notice: "Three weeks in advance, a full free refund, two weeks in advance, 15% deduction, and one week in advance, 20% deduction; Deduct 50% if the student does not arrive at school within one week",
        getPrice: "Get a quote",
        ViewPrice: "View Pirce",
        nav6: "School evaluation",
        allComment: "View All",
        score: "Evaluation score",
        reply: "Reply",
        nav7: "Cooperative Intermediary",

        totalPrice: "Total expense",
        course: "Course",
        coursePeriod: "CoursePeriod",
        stayType: "StayType",
        stayTime: "StayTime",
        coustNotice: "Please consult the remaining situation of the intermediary dormitory before selecting. If multiple dormitory types are required, the accommodation time cannot exceed the learning cycle",
        addClass: "Add a course",
        deleteClass: "Delete",
        nav8: "Benefits available",
        unusable: "Not available",
        gradeuate: 'Graduation Date',

        nation: "Nationality",
        qualification: "Qualify",
        degree: 'Degree',
        roomType: "RoomType",
        Demandnotice: "Please consult the remaining situation of the school dormitory before selecting",
        intime: "Admission",

        tuitionFee: "TuitionFee",
        ManagementFee: "ManagementFee",
        EducationFee: "EducationFee",
        registerFee: "RegisterFee",
        otherFee: "OtherFee",

    },
    jovSchool: {
        major: "Major",
        period: "Period",
        dormitory: "Dormitory",
        stayTime: "StayTime",
        addMajor: "AddMajor"
    },

    pagenation: {
        and: "Common",
        school: "Schools",
        agents: "Agents",
        medium: "Intermediaries",
        next: "Next page",
        last: 'Last page',
        jump: "Jump to",
        page: "Page"
    },

    group: {
        searchInput: "Search group",
        search: 'Search',
        reset: "Reset",
        country: "Country",
        region: "Region",
        schoolType: "SchoolType",
        joinIn: "Join in",
        noMore: "No more alumni groups available",

        groupTitle: "Basic personal information",
        groupNotice: '(You have not registered on this platform, please fill in the following information, and will join this group after approval.)',
        passport: "Passport",
        familyName: "Surname",
        name: "Name",
        readingTime: 'ReadingTime',
        LearningTime: "LearningTime",
        agentName: "AgentName",
        contactName: "Contact Name",
        applyJoin: "Apply to join",

        inputPassport: "Please enter your passport number",
        inputSurname: 'Please enter last name',
        inputName: "Please enter your name",
        inputTime: 'Please select a time',
        inputLongTime: "Please enter the learning duration",
        please: "Please select",
        inputContact: "Please enter intermediary contact information",

        infoSuccess:"Submitted successfully",
        patience:"Please be patient and wait for the platform review",
        sure:'sure',

        joinSuccess:"Successfully joined the group"
    },

    cor: {
        question: "please contact us",
        submit: "Submit"
    },
    schoolCor: {
        title: 'Filling in school admission information',
        name: 'Name',
        contact: "Contacts",
        position: 'Position',
        scale: 'Scale',
        type: 'Type',
        contactWay: "ContactWay",
        country: 'Country',
        region: "Region",
        address: "Address",
        email: "Email",
        passport: "Passport",
        website: "Website",
        license: "License",
        licenseNotice: "Contact person and legal person passport (just upload one for the same person)",
        account: "Account",
        enname: "Enname",
        platform:'Platform',
        province:'province',
        infoSuccess: "Submitted successfully",
        patience: "Please be patient and wait for the platform review",
        sure: 'Determine',
        joinSuccess: "Successfully joined the group",


        inputSchoolName: "Please enter the same name",
        inputEnName: 'Please enter the English name',
        inputName: "Please enter your local name",
        inputFirName: "Please enter the first and last names of your passport",
        inputPosition: 'Please enter the position',
        please: "Please select",
        inputPhone: "Please enter the contact",
        inputAccount: "Please enter the account",
        inputDetail: "Please enter detailed address",
        inputEmail: "Please enter the contact email",
        inputWebsite: 'Please enter the website address',
    },
    mediumCor: {
        account: "Account",
        type: "Select Type",
        Independent: "Independent intermediary",
        join: "Joining intermediary",
        title: "Filling in intermediary settlement information",
        platformrule: "Platform Entry Rules",
        meidumformrule: "Rules for intermediary settlement",
        agentName: "AgentName",
        agentNameEn:"agentNameEn",
        inputagentNameEn:'Please enter the English intermediary name',
        contact: "Contacts",
        contactWay: 'ContactWay',
        email: 'Email',
        position: 'Position',
        country: "Country",
        region: "Region",
        address: "Address",
        passport: 'Passport',
        website: 'Website',
        license: "License",
        licenseNotice: "Contact person and legal person passport (just upload one for the same person)",
        schoolType: "EnterSchool",

        inputAgentName: "Please enter the same name",
        inputName: "Please enter the name of your local language",
        inputPhone: 'Please enter the phone number',
        inputAccount: 'Please enter the account',
        inputEmail: 'Please enter the contact email',
        inputPostion: 'Please enter the position',
        please: "Please select",
        inputAddress: "Please enter detailed address",
        inputFirName: "Please enter the first and last names of your passport",
        inputWeb: "Please enter the website address"
    },
    bussinessCor: {
        title: "Business negotiation information filling",
        bussiness: 'Business',
        contact: 'Contacts',
        position: 'Position',
        contactWay: 'ContactWay',
        matter: "Negotiations",
        contactName: "Contact Name",
        nation: "Nation",
        remark:'remark',

        inputTopic: 'Please enter the negotiation topic',
        inputContact: 'Please enter the contact name',
        inputPassort: "Please enter your passport name",
        inputPostion: "Please enter the contact person'position",
        inputPhone: "Please enter the contact person phone number",
        inputAccount: 'Please enter the social account of the contact person',
        inputEmail: 'Please enter the contact email',
        please: "Please select",
        inputMatter: "Please enter the negotiation items",
        inputRemark:'Please enter the remark'
    },

    medium: {
        reserve: 'Reserve',

        morePic: "More pictures",
        contentlList: "Content List",
        complain: "Complain",
        connect: "Connect",
        attention: "Care",
        cancel: "Cancel Care",
        agejtBrief: "AgentBrief",
        agentAddress: "AgentAddress",
        passportName: "PassportName",

        nav1: "Latest Notifications/Events",
        nav5: "Refund Notice",
        nav5Notice: "Three weeks in advance, a full free refund, two weeks in advance, 15% deduction, and one week in advance, 20% deduction; Deduct 50% if the student does not arrive at school within one week",
        nav6: "Agent evaluation",
        allComment: "View All",
        score: "Evaluation score",
        reply: "Reply",

        getPrice: "GetPrice",
        getPriceList: "GetPriceList",
        priceTotal: "Total price",

        Ordinary: "Ordinary registration quotation",
        privacy: "Private Custom Quote",
        schoolName: "School",
        Education: "Education",
        speciality: "Speciality",
        roomType: "RoomType",
        personMajor: "PersonMajor",

        tuitionFee: "TuitionFee",
        ManagementFee: "ManagementFee",
        EducationFee: "EducationFee",
        registerFee: "RegisterFee",
        otherFee: "OtherFee",
        feeNotice: "Please consult the remaining situation of the school dormitory before selecting",

        success: "Payment succeeded",
        orderSn: "OrderSn",
        payTime: "PayTime",
        backHome: "Back Home",
        lookOrder: "View Order",
        priceType:"priceType",
        priceMoney:"priceMoney",
        remark:"remark"
    },

    mediumReserve: {
        personMsg: "Basic personal information",
        sex: "Sex",
        man: "Man",
        woman: "Woman",
        familyName: "Surname",
        name: "Name",
        contact: "Contact",
        passportName: "PortName",
        passport: "Passport",
        birthDate: "Birth Date",
        old: "Old",
        phone: "Phone",
        email: "Email",
        address: "Address",
        Acamajor: "Major",

        demandMsg: "Filling in demand information",
        nation: 'Nationality',
        qualification: "Qualify",
        degree: 'Degree',
        major: 'Major',
        roomType: "RoomType",
        notice: "Please consult the remaining situation of the school dormitory before selecting",
        intime: "Admission",
        tuitionFee: "TuitionFee",
        ManagementFee: "ManagementFee",
        EducationFee: "EducationFee",
        registerFee: "RegisterFee",
        otherFee: "OtherFee",


        plane: "Flight information entry",
        planeNotice: "(If there is no flight information temporarily, you can supplement it in my registration later)",
        planeCompany: "Company",
        planeNumber: "FlightNum",
        ontime: "BordingTime",
        arriveTime: "ArriveTime",
        isPlane: "IsPlane",
        planeType: 'Pick Type',


        emergency: "Add emergency contact",
        emergencyName: "Contact Name",
        emergencyRelation: 'Relation',
        emergencyPhone: "ContactPhone",
        emergencyConfirm: "Confirm",

        getPrice: "Getprice",

        Instructions: "Registration Instructions",
        InstructionTitle: "Before registering, please contact your intermediary or school in advance about the type of education you can apply for",
        CostDetails: "Expense Details",
        Collection: "Collection category",
        collectMoney: "Amount Collected",
        remark: "Remarks",
        actualPrice: "Actual paid expenses",
        pay: "Sign up and pay",
        requit: 'Refund instructions: Three weeks in advance, full refund free of charge; Deduct 15% for two weeks in advance; Deduct 20% for one week in advance; Deduct 50% if you do not arrive at school within one week;'
    },

    corperSchool:{
        name:'Partner Schools',
        inputSearch:'Please enter the school name for search',
        school:'school',
    }
}