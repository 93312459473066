export default {
    common:{
        selectProvince:"选择国家后可以对省份进行筛选",
        selectRegion:"选择省份后可以对市级进行筛选",
        yes:'是',
        no:'否',
        complaints:"视频投诉",
        complaintsReason:"投诉原因",
        complaintsContent:"投诉内容",
        complaintsTips:"请输入投诉内容",
        complaintsSuccess:"提交成功,感谢您的反馈",
        submit:'提交',
        connectTitle:"在线沟通",
        connect:"沟通",
        onLine:'在线',
        notOnLine:"不在线",
        date:"日期",
        searchMedium:"请输入中介名称进行搜索",
        PlatformAgent:"平台代理学校",
        profile:'简介',
        enroll:"立即报名",
        tomore:'查看更多'
    },
    header: {
        nameIndex:'首页',
        
        nameSchool:'找学校',
        nameLangSchool:"语言学校",
        nameAcademicSchool:'学历教育',
        nameJobSchool:'职业教育',

        nameMedium:"找中介",

        nameGroup:"校友群",

        nameCopoerate:"合作洽谈",
        nameCopoerateSchool:"学校入驻",
        nameCopoerateMedium:"中介入驻",
        nameCopoerateBussiness:'商务洽谈',

        nameAbout:"关于我们",

        nameCompany:"公司介绍",
        nameContact:"联系平台",

        nameMessage:"消息",

        nameLanguage:"语言",
        nameChinese:"中文",
        nameEnglist:"英文",

        nameLogin:"登陆",

        nameProfile:"我的资料",
        nameQRcode:"我的二维码",
        nameFollow:"我的关注",
        nameEntroll:"我报名的",
        nameCooperation:"我的合作洽谈",
        nameDoubt:'我的疑问',
        nameSetting:'设置',
        nameLogout:"退出"
    },
    footer:{
        AppDown:"APP Store下载",
        androidDown:"Android下载",
        agreement:"用户注册协议",
        policy:"隐私政策",
        recovery:"账号找回",
        contact:"联系我们",
        message:"网络谣言曝光台 | 网上有害信息举报 | 违法和不良信息举报：400-140-2108 | 未成年人守护热线：400-9922-556 | 举报邮箱：jubao@youxue.com"
    },
    login:{
        passwordLogin:"密码登录",
        codeLogin:"验证码登录",
        plase:'请选择',
        phone:"请输入您的手机号",
        code:"请输入验证码",
        requireCode:"获取验证码",
        againCode:"后重获验证码",
        password:"请输入密码",
        forget:"忘记密码",
        register:"没有账号 / 立即注册",
        nameLogin:"登录",
        agreement:"用户协议",
        privacy:"隐私政策",
        and:"和",
        agree:"勾选即默认同意",
        down:"下载游学网APP"
    },
    register:{
        inviteCode:'请输入邀请码',
        title:"注册",
        phone:"请输入手机号",
        code:"请输入验证码",
        requireCode:"获取验证码",
        againCode:"后重获验证码",
        password:"请输入密码",
        againPassword:"请再次确定密码",
        hasLogin:"已有账号?去登陆",
        message:"注册并完善信息",
        agreement:"用户协议",
        privacy:"隐私政策",
        and:"和",
        agree:"勾选即默认同意",
    },
    infomation:{
        title:"完善信息",
        engTitle:'英文名字',
        engInput:"您的英文名",

        familyName:"姓氏",
        familyInput:"您的姓氏（中文）",

        sex:"性别",
        sexMan:"男",
        sexWoMan:"女",

        name:"姓名",
        nameInput:"您的姓名（中文）",

        phone:'联系电话',
        phoneInput:"请输入联系电话",

        birthDate:"出生日期",
        birthInput:"请选择",

        email:"邮箱",
        emailInput:"请输入邮箱编号",

        passport:"护照号",
        passportInput:"请输入护照号",

        nation:'国籍',
        nationInput:"请选择",

        city:"所在城市",
        cityInput:"请输入您所在城市",

        school:"所在学校",
        schoolInput:"请输入您所在学校",
        invititaionCode:"邀请码",
        invititaionInput:"请输入邀请码",

        perfect:"跳过填写,以后完善",
        save:"保存"
    },
    forget:{
        title:"重置登陆密码",

        phonetitle:"手机号验证",
        newPassoword:"设置新密码",
        resetSuccess:"重置成功",

        phoneInput:"请输入手机号",
        codeInput:"请输入验证码",
        requireCode:"获取验证码",
        againCode:"后重获验证码",
        next:"下一步",

        setnewpassowrd:"请设置新密码",
        againPassword:"请再次输入新密码",

        last:"上一步",
        verify:"手机号验证",

        sure:"确定"
    },
    agreement:{
        title: "用户协议",
        content:"本协议为您与本APP管理者之间所订立的契约，具有合同的法律效力，请您仔细阅读。<br />一、本协议内容、生效、变更本协议内容包括协议正文及所有本APP已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。如您对协议有任何疑问，应向本APP咨询。您在同意所有协议条款并完成注册程序，才能成为本站的正式用户，您点击“我以阅读并同意本APP用户协议和法律协议”按钮后，本协议即生效，对双方产生约束力。只要您使用本APP平台服务，则本协议即对您产生约束，届时您不应以未阅读本协议的内容或者未获得本APP对您问询的解答等理由，主张本协议无效，或要求撤销本协议。您确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。<br />您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止注册程序或停止使用本APP平台服务。本APP有权根据需要不定期地制订、修改本协议及各类规则，并在本APP平台公示，不再另行单独通知用户。变更后的协议和规则一经在网站公布，立即生效。如您不同意相关变更，应当立即停止...<br />"
    },
    privacy:{
        title: "隐私政策",
        content:"隐私政策是通常是指网站、应用程序等依据隐私权政策制定的对用户信息处理的政策。<br /> 例如使用产品与/或服务时如何收集、使用、保存、共享和转让这些信息，以及为用户提供的访问、更新、删除和保护这些信息的方式。一般会在让用户开始使用产品与/或服务前仔细<br /> 阅读并确认用户已经充分理解本隐私政策所写明的内容并且同意后才能使用产品/服务。隐私政策是通常是指网站、应用程序等依据隐私权政策制定的对用户信息处理的政策。<br />例如使用产品与/或服务时如何收集、使用、保存、共享和转让这些信息，以及为用户提供的访问、更新、删除和保护这些信息的方式。一般会在让用户开始使用产品与/或服务前仔细阅读并确认用户已经充分理解本隐私政策所写明的内容并且同意后才能使用产品/服务。隐私政策是通常是指网站、应用程序等依据隐私权政策制定的对用户信息处理的政策。例如使用产品与/或服务时如何收集、使用、保存、共享和转让这些信息，以及为用户提供的访问、更新、删除和保护这些信息的方式。一般会在让用户开始使用产品与/或服务前仔细... <br />"
    },

    Profile:{
        attr:"我的头像",
        changeattr:"更换",

        enName:"我的英文名",
        enNameInput:"请填写英文名",
        
        familyName:"姓氏",
        familyNameInput:"请填写姓氏",

        name:"姓名",
        nameInput:"请填写姓名",

        birthDate:"我的生日",
        birthDateInput:'请选择出生日期',

        sex:"性别",
        sexMan:"男",
        sexWoman:'女',

        city:'所在城市',
        cityInput:"请填写所在城市",

        phone:"联系电话",
        phoneInput:"请填写联系电话",

        school:"毕业院校",
        schoolInput:"请填写毕业院校",

        email:"邮箱",
        emailInput:"请填写邮箱",

        nation:"国籍",
        nationInput:"请选择",

        passport:"护照号",
        passportInput:'请填写护照号',

        hide:'隐藏显示',

        change:"修改资料",
        
        save:"保存"
    },

    ErweiCode:{
        invite:"分享邀请",
        share:"分享二维码",
        myShare:"我的专属邀请码",
        shareContent:"成功邀请好友注册成功并输入你的邀请码，好友进行消费后，你就可以获得5%的分佣奖励哦。快邀请小伙伴开启新世界吧~",
        shareBtn:"分享",

        friend:"我的好友",
        order:"分佣订单",
    },
    centerFriend:{
        invite:"分享邀请",
        friend:"我的好友",
        order:"分佣订单",
        user:'用户',
        phone:'联系方式',
        creatTime:"创建时间"
    },
    centerOrder:{
        invite:"分享邀请",
        friend:"我的好友",
        order:"分佣订单",
        orderNumber:"订单编号",
        money:'佣金',
        time:"交易时间",
        friend:"好友"
    },
    centerCoroperate:{
        navSchool:"学校入驻",
        navMedium:"中介入驻",
        navBusiness:'商务洽谈',
        tableType:"入驻类型",
        name:"名称",
        contact:"联系人名称"
    },

    centerAccount:{
        changePassword:"修改密码",
        changePhone:"修改绑定手机号/邮箱",

        phone:'手机号',
        phonePlace:"请输入手机号",

        code:"验证码",
        codeInput:"请输入验证码",
        requireCode:"获取验证码",
        againCode:"后重获验证码",

        password:"密码",
        surePassword:"确认密码",
        setnewpassowrd:"请设置新密码",
        againPassword:"请再次输入新密码",

        sureChange:"确认修改",

        email:"邮箱号码",
        emailCode:"邮箱号",
        emailInput:"请输入邮箱号"
    },
    setting:{
        account:"账号和安全",
        message:"消息管理",
        privacy:"隐私管理",
        logout:"退出登录",
        name:"菲律宾游学网"
    },
    school:{
        searchInput:"请输入学校名称进行搜索",
        search:"搜索",
        reset:"重置",
        Comprehensive:"综合排序",
        Price:"价格排序",
        teachers:"师资排序",
        food:"伙食排序",
        environment:"环境排序",
        scale:"规模排序",
        country:"国家",
        province:"省份",
        region:"地区",
        schoolType:"学校类型",
        ScoringPrice:"评分价格",
        SalesRanking:"销量排序",

        Cooperation:"合作洽谈",
        schoolesettlement:"学校入驻",
        myDoubt:"我的疑问"
    },
    agent:{
        searchInput:"请输入中介名称进行搜索",
        search:"搜索",
        reset:"重置",
        Comprehensive:"综合素质",
        Price:"价格排序",
        sales:"销量排序",
        country:"国家",
        region:"地区",
    },
    linksBar:{
        index:"首页",
        back:"返回上一页",

        Findschool:'找学校',

        langSchool:"语言学校",
        langSchoolDetail:"语言学校详情",
        langSchoolReserve:"立即报名",
        langSchoolPriceList:"报价清单",
        langSchoolComment:"全部评价",
        
        academicSchool:"学历教育",
        academicSchoolDetail:"学历教育详情",

        jobSchool:"职业教育",
        jobSchoolDetail:"职业教育详情",

        group:"校友群",
        joinGroup:"加入校友群",
        accord:"用户注册协议",
        conceal:"隐私政策",
        retrieve:"账号找回",
        contact:"联系我们",
        about:"关于我们",

        findAgent:"找中介",
        reserve:"立即报名",

        medium: "找中介",
        mediumDetail: "详情",
        mediumReserve: "立即预订",
        mediumPriceList: "价格列表",
        mediumComment: "全部评论",
        Cooperation: "合作洽谈",
        schoolesettlement: "学校入驻",
        myDoubt: "我的疑问",
        means:"我的资料",
        code:"我的二维码",
        friend:"我的好友",
        SubcontractingOrder:"分佣订单",
        cares:"我的关注",
        entroll:"我的报名",
        coroperation:"我的合作洽谈",
        doubt:"我的疑问",
        setting:"设置"
    },
    languageSchool:{
        morePic:"更多图片",
        contentlList:"内容列表",
        complain:"视频投诉",
        connect:"在线沟通",
        attention:"关注",
        cancel:"取消关注",
        enroll:"立即报名",
        schoolBrief:"学校简介",
        schoolAddress:"学校地址",

        notice:"最新通知",
        dormitoryTable:"宿舍情况表",
        quotedPrice:"课程自动报价",
        basicRate:"基础费用",
        evaluation:"学校评论",
        Intermediary:"合作中介",
        apply:"合作中介",

        nav1:"最新通知/活动",
        nav2:"宿舍情况表",
        nav3:"课程自动报价",
        nav3Notice:"(此报价单不包含当地费用，详情请咨询中介或查看图片)",
        nav4:"基础费用",
        intime:"入学日期",
        isPlane:"是否接机",
        planeType:"接机类型",
        register:"注册费",
        other:"其他费用",

        course:"选择课程",
        coursePeriod:"课程周期",
        stayType:"住宿类型",
        stayTime:"住宿时间",
        coustNotice:"选择前请先咨询中介宿舍剩余情况,需要多宿舍类型时住宿时间不可超过学习周期",
        addClass:"添加课程",
        deleteClass:"删除此课程",
        ViewPrice:"查看报价",

        nav5:"退款须知",
        nav5Notice:"提前三周,全额免费退款,提前两周,扣15%,提前一周,扣20%;一周以内或未到校,扣50%;",
        getPrice:"获取报价(优惠)",
        totalPrice:"费用总额",
        nav8:"可享优惠",
        unusable:"不可用",
        gradeuate:'毕业日期',
        nav6:"学校评价",
        allComment:"查看全部",
        score:"学校评价分数",
        reply:"回复",
        nav7:"合作中介",

        nation: "国籍",
        qualification: "就读学历",
        degree: '就读专业',
        roomType: "房型选择",
        Demandnotice: "选择前请先咨询学校宿舍剩余情况",
        intime: "入学日期",

        tuitionFee: "学费",
        ManagementFee: "外国人管理费",
        EducationFee: "教育管理费",
        registerFee: "外国人注册金",
        otherFee: "其他费用",
    },
    langReserve:{
        personMsg:"个人基础信息",
        nation:'国籍',
        familyName:"姓氏",
        name:"名字",
        sex:"性别",
        man:"男",
        woman:"女",
        passportName:"护照名",
        passport:"护照号",
        birthDate:"出生年月",
        old:"年龄",
        phone:"联系电话",
        email:"邮箱",
        address:"家庭住址",

        demandMsg:"需求信息填写",
        intime:"入学日期",
        planeType:"接机类型",
        isPlane:"是否接机",
        course:"课程选择",
        coursePeriod:"周期选择",
        dormitoryWay:"住宿类型",
        dormitoryTime:"住宿时间",
        deleteClass:"删除此课程",
        classNotice:"选择前请先咨询中介宿舍剩余情况，需要多宿舍类型时住宿时间不可超过学习周期",
        register:"注册费",
        other:"其他费用",
        addClass:"添加课程",

        emergencyTitle:"添加紧急联系人",
        contactName:'联系人姓名',
        relation:"与您关系",
        contact:'联系电话',
        sureContact:"确认联系方式",

        discountTitle:"报名优惠",
        discountShare:"可享优惠",
        usable:'不可用',

        gradeuate:"毕业日期",
        year:"年",
        month:'月',
        day:"日",

        getPrice:"获取报价(优惠)",
        againPrice:"再次获取报价",

        priceType:"收款类目",
        pricePeriod:"周期",
        priceMoney:"收款金额",
        remark:"备注",
        actualPrice:"实际支付费用",
        pay:"报名并支付",
        reuqitNotice:"退款须知：提前三周，全额免费退款；提前两周，扣15%；提前一周，扣20%；一周以内或未到校，扣50%;",

        please:"请选择",
        inputFirName:"请输入您的姓氏",
        inputName:'请输入您的名字',
        inputpassportName:'请输入您的护照名',
        passportNumber:'请输入您的护照号',
        inputpassport:'请输入您的护照号',
        inputTime:'选择日期',
        inputPhone:'请输入你的联系电话',
        inputEmail:"请输入你的邮箱",
        inputAddress:"请输入你的家庭住址",
        yes:"是",
        no:'否',
        oneweek:'一周',
        twoweek:'两周',
        threeweek:'三周',
        forthweek:'四周',
        fiveweek:'五周',
        sixweeK:"六周",
        sevenweek:"七周",
        eightweek:"八周",
        nineweek:'九周',
        tenweek:'十周',
        emergencyName:"请输入姓名",
        emercontactType:"请输入联系方式",
        sureContactType:"请确认联系方式",

        inputPlaneName:"请输入航空公司名称",
        inputPlaneNum:"请输入航班号",
        
    },
    academicSchool:{
        morePic:"更多图片",
        contentlList:"内容列表",
        complain:"视频投诉",
        connect:"在线沟通",
        attention:"关注",
        cancel:"取消关注",
        enroll:"立即报名",
        schoolBrief:"学校简介",
        schoolAddress:"学校地址",

        notice:"最新通知",
        dormitoryTable:"宿舍情况表",
        quotedPrice:"课程自动报价",
        basicRate:"基础费用",
        evaluation:"学校评论",
        Intermediary:"合作中介",
        apply:"合作中介",
        ViewPrice:"查看报价",

        nav1:"最新通知/活动",
        nav2:"宿舍情况表",
        nav3:"课程自动报价",
        nav3Notice:"(此报价单不包含当地费用，详情请咨询中介或查看图片)",
        nav4:"基础费用",
        intime:"入学日期",
        isPlane:"是否接机",
        planeType:"接机类型",
        register:"注册费",
        other:"其他费用",

        course:"选择课程",
        coursePeriod:"课程周期",
        stayType:"住宿类型",
        stayTime:"住宿时间",
        coustNotice:"选择前请先咨询中介宿舍剩余情况,需要多宿舍类型时住宿时间不可超过学习周期",
        addClass:"添加课程",
        deleteClass:"删除此课程",

        nav5:"退款须知",
        nav5Notice:"提前三周,全额免费退款,提前两周,扣15%,提前一周,扣20%;一周以内或未到校,扣50%;",
        getPrice:"获取报价",
        totalPrice:"费用总额",
        nav8:"可享优惠",
        unusable:"不可用",
        gradeuate:'毕业日期',
        nav6:"学校评价",
        allComment:"查看全部",
        score:"学校评价分数",
        reply:"回复",
        nav7:"合作中介",


        nation: "国籍",
        qualification: "就读学历",
        degree: '就读专业',
        roomType: "房型选择",
        Demandnotice: "选择前请先咨询学校宿舍剩余情况",
        intime: "入学日期",
        tuitionFee: "学费",
        ManagementFee: "外国人管理费",
        EducationFee: "教育管理费",
        registerFee: "外国人注册金",
        otherFee: "其他费用",
    },
    jovSchool:{
        major:"选择专业",
        period:"学习周期",
        dormitory:"住宿类型",
        stayTime:"住宿时间",
        addMajor:"添加专业"
    },
    pagenation:{
        and:"共",
        school:"所学校",
        agents:"所中介",
        medium:"所中介",
        next:"下一页",
        last:'上一页',
        jump:"跳转至",
        page:"页数"
    },
    schoolInfo:{
        agentJie:'中介简介',
        agentAddress:'中介地址',
        wechat:'微信',
        visa:'签证'
    },
    group:{
        searchInput:"请输入群聊名称进行搜索",
        search:'搜索',
        reset:"重置",
        country:"国家",
        region:"地区",
        schoolType:"学校类型",
        joinIn:"加入校友群",
        noMore:"暂无更多校友群",

        groupTitle:"个人基础信息",
        groupNotice:'(您未经过本平台报名,请填写以下资料,审核通过后将加入此群)',
        passportName:"护照名",
        passport:"护照",
        familyName:"姓氏",
        name:"名字",
        readingTime:'入读时间',
        LearningTime:"学习时长",
        agentName:"中介名称",
        contactName:"联系方式",
        applyJoin:"申请加入",

        inputPassport:"请输入护照号",
        inputSurname:'请输入姓氏',
        inputName:"请输入姓名",
        inputTime:'请选择时间',
        inputLongTime:"请输入学习时长",
        please:"请选择",
        inputContact:"请输入中介联系方式",

        infoSuccess:"提交成功",
        patience:"请耐心等待平台审核",
        sure:'确定',

        joinSuccess:"已加入该群成功"
    },
    schoolCor:{
        title:'学校入驻信息填写',
        name:'名称',
        contact:"联系人姓名",
        position:'联系人职位',
        scale:'学校规模',
        type:'学校类型',
        contactWay:"联系人方式",
        country:'国家',
        region:"地区",
        address:"详细地址",
        email:"联系人邮箱",
        passportName:"护照名",
        passport:"护照名",
        website:"网址",
        license:"营业执照",
        licenseNotice:"联系人、法人护照(如同一人,上传一张即可)",
        account:"社交账号",
        enname:"英文名称",
        
        province:'省份',
        platform:'社交平台',
        inputSchoolName:"请输入跟营业执照名称一致",
        inputEnName:'请输入学校英文名称',
        inputName:"请输入本国语言的名字",
        inputFirName:"请输入护照的名与姓",
        inputPosition:'请输入职位',
        please:"请选择",
        inputPhone:"请输入联系人手机号码",
        inputAccount:"请输入联系人社交账号",
        inputDetail:"请输入详细地址",
        inputEmail:"请输入联系人邮箱",
        inputWebsite:'请输入网址'
    },
    mediumCor:{
        agentNameEn:"中介英文名",
        type:"选择类型",
        inputagentNameEn:'请输入中介的英文名称',
        Independent:"独立中介",
        join:"加盟中介",
        title:"中介入驻信息填写",
        platformrule:"入驻规则",
        meidumformrule:"入驻规则",
        agentName:"中介名称",
        contact:"联系人姓名",
        contactWay:'联系人方式',
        email:'联系人邮箱',
        position:'联系人职位',
        country:"国家",
        region:"地区",
        address:"详细地址",
        passportName:"护照名",
        passport:'护照名',
        website:'网址',
        license:"营业执照",
        licenseNotice:"联系人、法人护照(如同一人,上传一张即可)",
        schoolType:"选择入驻学校",
        account:"社交账号",

        inputAgentName:"请输入跟营业执照名称一致",
        inputName:"请输入本国语言的名字",
        inputPhone:'请输入联系人手机号码',
        inputAccount:'请输入联系人社交账号',
        inputEmail:'请输入联系人邮箱',
        inputPostion:'请输入职位',
        please:"请选择",
        inputAddress:"请输入详细地址",
        inputFirName:"请输入护照的名与姓",
        inputWeb:"请输入网址"
    },
    cor:{
        question:"有疑问,联系我们",
        submit:"提交"
    },
    bussinessCor:{
        title:"商务洽谈信息填写",
        bussiness:'商务洽谈',
        contact:'联系人姓名',
        position:'联系人职位',
        contactWay:'联系人方式',
        matter:"洽谈事项",
        contactName:"护照名",
        nation:"国籍",
        remark:'备注',
        inputRemark:'请输入您的备注',

        inputTopic:'请输入洽谈主题',
        inputContact:'请输入联系人姓名',
        inputPassort:"请输入护照名",
        inputPostion:"请输入联系人职位",
        inputPhone:"请输入联系人手机号码",
        inputAccount:'请输入联系人社交账号',
        inputEmail:'请输入联系人邮箱',
        please:"请选择",
        inputMatter:"请输入洽谈事项"
    },

    medium:{
        reserve:'立即报名',

        morePic:"更多图片",
        contentlList:"内容列表",
        complain:"视频投诉",
        connect:"在线沟通",
        attention:"关注",
        cancel:"取消关注",
        enroll:"立即报名",
        agentBrief:"学校简介",
        agentAddress:"学校地址",

        nav1:"最新通知/活动",
        nav5:"退款须知",
        nav5Notice:"提前三周,全额免费退款,提前两周,扣15%,提前一周,扣20%;一周以内或未到校,扣50%;",
        nav6:"中介评价",
        allComment:"查看全部",
        score:"中介评价分数",

        getPrice:"获取报价",
        getPriceList:"获取报价清单",
        priceTotal:"费用总额",
        reply:"回复",

        Ordinary:"普通报名报价",
        privacy:"私人订制报价",
        schoolName:"学校名称",
        Education:"选择学历",
        speciality:"选择专业",
        roomType:"房间类型",
        personMajor:"私人订制专业",

        tuitionFee:"学费",
        ManagementFee:"外国人管理费",
        EducationFee:"教育管理费",
        registerFee:"外国人注册金",
        otherFee:"其他费用",
        feeNotice:"选择前请咨询学校宿舍剩余情况",
        priceType:"收款类目",
        priceMoney:"收款金额",
        remark:"备注",

        success:"支付成功",
        orderSn:"订单号",
        payTime:"支付时间",
        backHome:"返回首页",
        lookOrder:"查看订单",
    },
    mediumReserve:{
        personMsg:"个人基础信息",
        demandMsg: "填写需求信息",
        sex:"性别",
        man:"男",
        woman:"女",
        familyName:"姓氏",
        name:"名字",
        contact:"联系方式",
        passportName:"护照名",
        passport:"护照号",
        birthDate:"出生年月",
        old:"年龄",
        phone:"联系电话",
        email:"邮箱",
        address:"家庭住址",
        passportName:"护照名",
        Acamajor:"所选专业",

        nation:'国籍',
        qualification:"自身学历",
        degree:'报读学历',
        major:'私人订制专业',
        roomType:"房间类型",
        notice:"择前请先咨询学校宿舍剩余情况",
        intime:"入学日期",
        registerFee:"注册费",
        EducationFee:"教育管理费",
        otherFee:"其他费用",
        ManagementFee:"外国人管理费",
        tuitionFee:"外国人注册金",

        plane:"航班信息录入",
        planeNotice:"(若暂时没有航班信息,可后续在我的报名中进行补充)",
        planeCompany:"航空公司",
        planeNumber:"航班号",
        ontime:"登机时间",
        arriveTime:"到达时间",
        isPlane:"是否需要接机",
        planeType:'接机类型',

        emergency:"添加紧急联系人",
        emergencyName:"联系人姓名",
        emergencyRelation:'与您关系',
        emergencyPhone:"联系电话",
        emergencyConfirm:"确认联系方式",

        getPrice:"获取报价",

        Instructions:"报名须知",
        InstructionTitle:"报名前请提前与中介或学校联系自身学历可报读的学历类型",
        CostDetails:"费用明细",
        Collection:"收款类目",
        collectMoney:"收款金额",
        remark:"备注",
        actualPrice:"实际支付费用",
        pay:"报名并支付",
        requit:'退款须知：提前三周，全额免费退款；提前两周，扣15%；提前一周，扣20%；一周以内或未到校，扣50%；'
    },

    corperSchool:{
        name:'合作学校',
        inputSearch:'请输入学校名称进行搜索',
        school:'学校',
    }
}